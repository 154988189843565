<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="ID" prop="card">
                    <el-input clearable v-model="searchObj.card" style="width: 220px;"
                              placeholder="请填写ID"></el-input>
                </el-form-item>
				<el-form-item label="昵称" prop="authUserName">
                    <el-input clearable v-model="searchObj.nickname" style="width: 220px;"
                              placeholder="请填写姓名"></el-input>
                </el-form-item>
                <el-form-item label="注册时间段" prop="authUserName">
                    <el-date-picker
                            clearable
                            style="width: 220px;"
                            v-model="selectDate"
                            type="daterange"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            @change="selectedDate"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="首次消费时间段" prop="authUserName">
                    <el-date-picker
                            clearable
                            style="width: 220px;"
                            v-model="payDate"
                            type="daterange"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            @change="paySelectDate"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="店铺" prop="name">
                    <el-select v-model="searchObj.store_id" clearable placeholder="请选择店铺">
                        <el-option
                                v-for="item in storeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
				<el-form-item label="会员等级" prop="vip_id">
				    <el-select v-model="searchObj.vip_id" clearable placeholder="请选择会员等级">
				        <el-option
				                v-for="item in vipLevel"
				                :key="item.id"
				                :label="item.name"
				                :value="item.id">
				        </el-option>
				    </el-select>
				</el-form-item>
                <el-form-item label="上次登录日期" prop="date">
                    <el-select v-model="searchObj.month_type" clearable placeholder="上次登录日期">
                        <el-option label="一个月前" :value='1'></el-option>
                        <el-option label="三个月前" :value='2'></el-option>
                        <el-option label="六个月前" :value='3'></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="账号" prop="username">
                    <el-input clearable v-model="searchObj.phone" style="width: 220px;" placeholder="请填写电话"></el-input>
                </el-form-item>

                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div @click="doSearch()" class="fill-btn">查询</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div @click="excelTeamOrder()" class="fill-btn">导出</div>
                </el-form-item>
                <div>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div  class="fill-btn ">本日新增：{{this.day_count}}</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;" >
                    <div  class="fill-btn ">本周新增：{{this.week_count}}</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div  class="fill-btn ">本月新增：{{this.month_count}}</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div  class="fill-btn ">总用户数量：{{this.total}}</div>
                </el-form-item>
                </div>
                <!--<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">-->
                <!--<el-button type="danger" @click="getShowSms">短信提醒</el-button>-->

                <!--</el-form-item>-->
                <!--<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">-->
                <!--<el-button type="info" @click="createUser">新增</el-button>-->
                <!--&lt;!&ndash;<div class="fill-btn" ></div>&ndash;&gt;-->
                <!--</el-form-item>-->
            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text"><!--已选择{{selectData.length}}项--></div>
            </div>
            <el-table
                    :data="listInfo"
                    stripe
                    @selection-change="handleSelectionChange"
                    cell-class-name="text-center"
                    header-cell-class-name="header-row-class-name"
            >
                <el-table-column prop="id" label="ID" min-width="100px">
					<template slot-scope="{row}">
					    {{row.is_vip==2?row.special_card:row.normal_card}}
					</template>
				</el-table-column>
                <el-table-column prop="phone" label="账号" min-width="120px"></el-table-column>
                <el-table-column prop="name" label="会员等级" min-width="120px">
					<template slot-scope="{row}">
						<el-popover
							v-if="row.is_vip==2"
						    placement="top"
						    width="200"
						    trigger="click">
							<div class="box-card">
							  <div class="text item">
							    姓名：{{row.get_king_log.user_name}}
							  </div>
							  <div class="text item">
							    出生日期：{{row.get_king_log.birthday}}
							  </div>
							</div>
							<div slot="reference" style="cursor: pointer;">
								学生王卡
							</div>
						  </el-popover>
						
						<div v-else>
							{{row.get_vip.name}}
						</div>
					</template>
				</el-table-column>
                <el-table-column prop="store_name" label="首次消费店铺" min-width="120px"></el-table-column>
                <el-table-column prop="nickname" label="昵称" min-width="120px"></el-table-column>
                <el-table-column prop="head" label="头像" min-width="60">
                    <template slot-scope="{row}">
                        <!-- <img :src="row.head" height="60" /> -->
						<el-image style="width: 40px; height: 40px" :src="row.head" :preview-src-list="[row.head]">
						</el-image>
                    </template>
                </el-table-column>
                <el-table-column width="60" label="状态">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <sapn v-if="row.status == 1" type="primary" round>正常</sapn>
                            <sapn v-if="row.status == 2" type="warning" round>冻结</sapn>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="integral" label="积分余额" min-width="120px"></el-table-column>
                <el-table-column prop="price" label="钱包余额" min-width="120px"></el-table-column>
                <el-table-column prop="last_at" label="上次登录日期" min-width="120px"></el-table-column>
                <el-table-column prop="created_at" label="注册日期" min-width="120px"></el-table-column>
                <el-table-column label="操作" fixed="right" min-width="160px">
                    <template template slot-scope="scope">
                        <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                   @click="showUserInfo(scope.row)"></el-button>
                        <el-button type="primary" size="mini" @click="showMoneyChange(scope.row)">金额流水</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchObj.page"
                        :page-size="searchObj.limit"
                        @current-change="searchObj.page = $event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total"
                ></el-pagination>
            </div>
        </div>
        <!-- 金额流水 -->
        <el-dialog title="金额流水" :visible.sync="showMoney">
            <!--<HgDialog title="金额流水" :width="1000" :maskClose="false" v-model="showMoney">-->
            <div style="padding: 20px;">

                <el-table
                        :data="moneyList"
                        stripe
                        v-loading="MoneyLoading"
                        cell-class-name="text-center"
                        header-cell-class-name="header-row-class-name"
                >
                    <el-table-column prop="id" label="ID" width="60px"></el-table-column>
                    <el-table-column prop="price" label="金额" min-width="120px"></el-table-column>
                    <el-table-column width="200 " label="收支类型">
                        <template slot-scope="{row}">
                            <div class="btn-content">
                                <el-button v-if="row.type == 1" type="primary" size="mini" round>充值</el-button>
                                <el-button v-if="row.type == 2" type="warning" size="mini" round>赠送</el-button>
                                <el-button v-if="row.type == 3" type="warning" size="mini" round>消费</el-button>
                                <el-button v-if="row.type == 4" type="warning" size="mini" round>绑定王卡</el-button>
                                <el-button v-if="row.type == 5" type="warning" size="mini" round>订单退款</el-button>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="created_at" label="日期" width="148px"></el-table-column>
                </el-table>
                <div class="pagination-content">
                    <el-pagination
                            background
                            :current-page.sync="searchMoneyObj.page"
                            :page-size="searchMoneyObj.limit"

                            @current-change="selectPage($event,1)"
                            @size-change="handleSizeChange"
                            layout="total, prev, pager, next, jumper"
                            :total="MoneyTotal"
                    ></el-pagination>
                </div>
            </div>
            <!--</HgDialog>-->
        </el-dialog>
        <!-- 我的团队 -->
        <HgDialog title="我的团队" :width="1000" :maskClose="false" v-model="showTeamList">
            <div style="padding: 20px;">
                <div class="park-form">
                    <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                        <el-form-item label="账号">
                            <el-input v-model="teamObj.phone"></el-input>
                        </el-form-item>
                        <el-form-item style="margin-top: auto;margin-right: auto;margin-left: 20px;">
                            <div @click="getShowTeamList(teamData)" class="fill-btn">查询</div>
                        </el-form-item>
                    </el-form>
                </div>

                <el-table
                        :data="teamList"
                        stripe
                        v-loading="TeamLoading"
                        cell-class-name="text-center"
                        header-cell-class-name="header-row-class-name"
                >
                    <el-table-column prop="id" label="ID" min-width="120px"></el-table-column>
                    <el-table-column prop="phone" label="用户" min-width="120px"></el-table-column>
                </el-table>
                <div class="pagination-content">
                    <el-pagination
                            background
                            :current-page.sync="teamObj.page"
                            :page-size="teamObj.limit"

                            @current-change="selectPage($event,4)"
                            @size-change="handleSizeChangeTeam"
                            layout="total, prev, pager, next, jumper"
                            :total="teamListTotal"
                    ></el-pagination>
                </div>
            </div>
        </HgDialog>
        <!-- 用户编辑-->
        <el-dialog title="编辑用户" :visible.sync="showUser">
            <!--<HgDialog title="身份信息" :width="1000" :maskClose="false" v-model="showUser">-->
            <div v-loading="UserLoading" style="padding: 20px;">
                <el-form ref="form" :model="userInfo" label-width="80px">
                    <el-form-item label="账号">
                        <el-input v-model="userInfo.phone" :disabled="true"></el-input>
                    </el-form-item>


                    <el-form-item label="昵称">
                        <el-input v-model="userInfo.nickname"></el-input>
                    </el-form-item>
                    <el-form-item label="头像">
                        <el-upload
                                class="avatar-uploader"
                                action="/admin/upload"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="userInfo.head" :src="userInfo.head" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="状态">
                        <el-select v-model="userInfo.status" placeholder="请选择活动区域">
                            <el-option label="正常" :value='1'></el-option>
                            <el-option label="冻结" :value='2'></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="saveUser">保存</el-button>
                        <el-button @click="offuser">取消</el-button>
                    </el-form-item>
                </el-form>

            </div>
            <!--</HgDialog>-->
        </el-dialog>
        <!-- 用户添加 -->
        <el-dialog title="创建用户" :visible.sync="addUser">
            <!--<HgDialog title="创建用户" :width="1000" :maskClose="false" v-model="addUser">-->
            <div v-loading="UserLoading" style="padding: 20px;">
                <el-form ref="form" :model="userAdd" label-width="80px">
                    <el-form-item label="账号" required>
                        <el-input v-model="userAdd.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="公司">
                        <el-select v-model="userAdd.company_id" clearable placeholder="请选择公司">
                            <el-option
                                    v-for="item in company"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="密码" required>
                        <el-input v-model="userAdd.password"></el-input>
                    </el-form-item>
                    <el-form-item label="昵称">
                        <el-input v-model="userAdd.nickname"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="role" required>
                        <el-select v-model="userAdd.role" clearable placeholder="请选择角色">
                            <el-option clearable v-for="item in roleOptions" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="类型" required>
                        <el-select v-model="userAdd.role_type" placeholder="类型">
                            <el-option label="直客" :value='1'></el-option>
                            <el-option label="渠道" :value='2'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="能否提现" required>
                        <el-select v-model="userAdd.is_withdrawal" placeholder="类型">
                            <el-option label="能" :value='1'></el-option>
                            <el-option label="不能" :value='2'></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="adminAddUser">保存</el-button>
                        <el-button @click="offuser">取消</el-button>
                    </el-form-item>
                </el-form>

            </div>
        </el-dialog>
        <!--</HgDialog>-->
        <!-- 用户导入 -->
        <el-dialog title="用户导入" :visible.sync="excelUser">
            <!--<HgDialog title="导入用户" :width="1000" :maskClose="false" v-model="excelUser">-->
            <div v-loading="UserLoading" style="padding: 20px;">
                <el-form ref="form" :model="userExcel" label-width="80px">
                    <el-form-item label="文件" required>
                        <input type="file" @change="getFile"/>
                    </el-form-item>
                    <el-form-item label="账号角色">
                        <el-select v-model="userExcel.role" placeholder="请选择">
                            <el-option
                                    v-for="item in roleOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="excelCreate">保存</el-button>
                        <el-button @click="offuser">取消</el-button>
                    </el-form-item>
                </el-form>

            </div>
        </el-dialog>
        <!--</HgDialog>-->
        <!-- 用户短信提醒 -->
        <HgDialog title="积分修改" :width="1000" :maskClose="false" v-model="excelSms">
            <div v-loading="UserLoading" style="padding: 20px;">
                <el-form ref="form" :model="userSms" label-width="80px">

                    <el-form-item label="余额" required>
                        <el-input v-model="price"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="priceSave">保存</el-button>

                    </el-form-item>
                </el-form>

            </div>
        </HgDialog>
    </div>
</template>

<script>
    import {delToast, saveToast, formVerify} from './../../utlis/decorators';

    export default {
        name: 'tenant_account',
        props: {
            parkId: {
                type: [Number, String],
                default: null
            }
        },
        filters: {
            isLogin(num) {
                switch (num) {
                    case 1:
                        return '待审核';
                    case 2:
                        return '通过';
                    case 3:
                        return '冻结';
                    default:
                        break;
                }
            },

            walletType(num) {
                switch (num) {
                    case 1:
                        return '直推';
                    case 2:
                        return '间推';
                    case 3:
                        return '提现';
                    case 4:
                        return '红包';
                    default:
                        break;
                }
            },
        },
        data() {
            return {
                //
                MoneyTotal: 0,
                ClassTotal: 0,
                IntegralTotal: 0,
                isShowAgreeButton: false,
                user_id: '',
                day_count:0,
                week_count:0,
                month_count:0,
                UserLoading: false,
                showUser: false,
                addUser: false,
                excelUser: false,
                userAdd: {},
                userInfo: {},
                userExcel: {
                    phone: '',
                    pid: '',
                    role: '',
                    excels: ''
                },
                showClass: false,
                ClassLoading: false,
                searchClassObj: {
                    user_id: '',
                    page: 1,
                    limit: 3
                },
                ClassList: [],
                excelAZ: [],
                searchIntegralObj: {
                    user_id: '',
                    type: '',
                    source: '',
                    page: 1,
                    limit: 3
                },
                showIntegral: false,
                IntegralList: [],
                IntegralLoading: false,
                MoneyLoading: false,
                moneyList: [],
                showTeamList: false,
                TeamLoading: false,
                teamListTotal: 0,
                searchMoneyObj: {
                    type: '',
                    source: '',
                    page: 1,
                    limit: 3,
                    user_id: ''
                },
                teamObj: {
                    page: 1,
                    limit: 3,
                    id: ''
                },
                moneyOptions: [
                    {value: 1, label: '直推'},
                    {value: 2, label: '间推'},
                    {value: 3, label: '提现'},
                    {value: 4, label: '红包'},
                ],
                showMoney: false,
                teamList: [],
                userCreate: [],
                roleOptions: [
                    {value: 2, label: '客户经理'},
                    {value: 3, label: '签件经理'},
                    {value: 4, label: '普通用户'},
                    {value: 1, label: '游客'},
                ],
                typeOptions: [
                    {value: 1, label: '直客'},
                    {value: 2, label: '渠道'},
                ],
                teamData: {},

                loading2: false,
                searchObj: {
					card:'',
                    nickname: '',
                    start_time: '',
                    end_time: '',
                    store_start_time: '',
                    store_end_time: '',
                    role: '',
                    phone: '',
                    vip_id: '',
                    page: 1,
                    week: 1,
                    limit: 6
                },
                listInfo: [],
                selectDate: [],
                payDate: [],
                //
                loading: false,
                currentTop: 0,
                total: 0,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                searchForm: {
                    page: 1,
                    limit: 1,
                    nickname: ''
                },
                showTeam: [],
                form: {
                    nickname: ''
                },
                file: null,
                excelSms: false,
                userSms: {},
                price: 0,
                uid: '',
                company: [],
                storeList:[],
                vipLevel:[{id:-1,name:'学生王卡'}],
            };
        },
        created() {
            this.userList();
            this.colWxcel();
            this.getStoreLists();
            this.getVipAll();
        },
        computed: {
            getRules() {
                return {
                    authTenantId: [
                        {
                            required: true,
                            message: `名称不能为空`,
                            trigger: ['blur', 'change']
                        }
                    ],
                    authUserName: [
                        {
                            required: true,
                            message: `姓名不能为空`,
                            trigger: ['blur', 'change']
                        }
                    ]
                };
            }
        },
        watch: {
            'searchObj.page'() {
                this.userList();
            },
            'searchMoneyObj.page'() {
                this.showMoneyChange();
            },

            'teamObj.page'() {

                this.getShowTeamList(this.teamData);
            },
        },
        methods: {

            //获取所有会员等级
            async getVipAll() {
                let [e, data] = await this.$api.getVipAllApi();
                if (e) return;
                if (data.code === 200) {
                    this.vipLevel = this.vipLevel.concat(data.data);
                }
            },
			//获取店铺列表
            async getStoreLists() {
                let [e, data] = await this.$api.storeLists();
                if (e) return;
                if (data.code === 200) {
                    this.storeList = data.data;
                }
            },
            //头像
            handleAvatarSuccess(res, file) {
                this.userInfo.head = res.url;
                this.userAdd.head = res.url;
                console.log(this.userAdd.head);
            },
            beforeAvatarUpload(file) {
                // const isJPG = file.type === 'xls/xlsx';
                // const isLt2M = file.size / 1024 / 1024 < 2;
                //
                // if (!isJPG) {
                //     this.$message.error('上传头像图片只能是 JPG,png 格式!');
                // }
                // if (!isLt2M) {
                //     this.$message.error('上传头像图片大小不能超过 2MB!');
                // }
                // return isJPG && isLt2M;
            },
            //导出
            async excelTeamOrder() {
                this.$downFile('userExcel', this.searchObj, '用户');
            },
            // 翻页

            getFile(e) {

                this.file = e.target.files[0];


            },
            selectPage($event, num) {
                if (num === 1) {
                    this.searchMoneyObj.page = $event
                    console.log(this.searchMoneyObj.page);
                    // this.userList();
                } else if (num === 2) {
                    this.searchIntegralObj.page = $event
                    console.log(this.searchIntegralObj.page);
                    // this.userList();
                } else if (num === 3) {
                    this.searchClassObj.page = $event
                    console.log(this.searchClassObj.page);
                    // this.userList();
                } else if (num === 4) {
                    this.teamObj.page = $event

                    console.log(this.teamData);
                    this.getShowTeamList(this.teamData);
                }
            },
            // 信息
            async showUserInfo(item) {
                if (item.is_login === 1) {
                    this.isShowAgreeButton = true
                } else {
                    this.isShowAgreeButton = false
                }
                this.UserLoading = true;
                this.user_id = item.id;
                this.showUser = true;
                let obj = {
                    user_id: this.user_id
                };
                let [e, data] = await this.$api.userRegDetails({id: this.user_id});
                console.log('身份信息', data.data);
                this.userInfo = data.data;

                this.UserLoading = false;
            },

            async createUser() {
                this.addUser = true;
            },
            // 流水
            async showMoneyChange(item) {
                this.showMoney = true;
                if (item) {
                    this.user_id = item.id;
                    console.log('单行信息', item);
                    this.searchMoneyObj.user_id = this.user_id;
                    this.MoneyLoading = true;
                    let [e, data] = await this.$api.userMoneyLog(this.searchMoneyObj);
                    this.moneyList = data.data.data;
                    this.MoneyTotal = data.data.total
                    console.log('流水信息', data);
                } else {
                    this.searchMoneyObj.user_id = this.user_id;
                    this.MoneyLoading = true;
                    let [e, data] = await this.$api.userMoneyLog(this.searchMoneyObj);
                    this.moneyList = data.data.data;
                    this.MoneyTotal = data.data.total

                    console.log('流水信息', data.data.data);
                }
                this.MoneyLoading = false;
            },
            //导入
            async excelCreate() {
                let form = new FormData();
                form.append('excels', this.file)
                for (let userExcelKey in this.userExcel) {
                    form.append(userExcelKey, this.userExcel[userExcelKey])
                }
                let [e, data] = await this.$api.createExcel(form);
                if (e) return;
                if (data.code == 200) {

                    this.$message.success(data.msg);
                    this.userList();
                }
                this.TeamLoading = false;
            },
            //短信提醒
            async SmsCreate() {
                let [e, data] = await this.$api.userSms(this.userSms);
                if (e) return;
                if (data.code == 200) {
                    this.$message.success(data.msg);

                }
                this.excelSms = false;
            },

            @saveToast()
            async roleStatus(item, status) {
                let [e, data] = await this.$api.userRoleStatus({
                    id: item.id,
                    status: status
                });
                if (e) return;
                if (data.code == 200) {
                    this.userList();
                    this.$message.success(data.msg);

                }

            },
            @saveToast()
            async saveUserTop(item) {
                let [e, data] = await this.$api.saveTop({
                    id: item.id,
                });
                if (e) return;
                if (data.code == 200) {
                    this.userList();
                    this.$message.success(data.msg);
                }
            },
            //积分修改
            async tkWallet(wall) {

                this.excelSms = true;
                this.uid = wall.id;
                this.price = wall.balance;
            },

            async priceSave() {

                let [e, data] = await this.$api.saveWallet({
                    id: this.uid,
                    price: this.price
                });
                if (e) return;
                if (data.code == 200) {
                    this.excelSms = false;
                }

            },

            async colWxcel() {
                let [e, data] = await this.$api.excelColumns();
                if (e) return;
                if (data.code == 200) {
                    this.excelAZ = data.data;
                }
                this.excelSms = false;
            },

            // 我的团队
            async getShowTeamList(item) {
                this.teamData = item;

                this.user_id = item.id;
                this.showTeamList = true;
                this.TeamLoading = true;
                this.teamObj.id = this.user_id;
                let [e, data] = await this.$api.userTeamLog(this.teamObj);
                if (data.code == 200) {
                    this.teamList = data.data.data;
                    this.teamListTotal = data.data.total

                    console.log('流水信息', data);
                }
                this.TeamLoading = false;

            },
            //导出
            async userExport() {
                let a = document.createElement('a');
                a.href = 'http://www.urxms.com/user.xls';
                a.tartget = '_blank';
                a.click();
                // this.$downFile('userExport',this.searchForm,'用户模板');
            },
            async getShowExcel() {

                this.excelUser = true;


            },
            async getShowSms() {

                this.excelSms = true;
                // this.TeamLoading = true;
                // this.teamObj.id = this.user_id;
                //
                // if (data.code == 200) {
                //     this.teamList = data.data.data;
                //     this.teamListTotal = data.data.total
                //
                //     console.log('流水信息', data);
                // }
                // this.TeamLoading = false;

            },


            // 用户类型
            isRole(e) {
                switch (e.role) {
                    case 1:
                        return '游客';
                    case 2:
                        return '客户经理';
                    case 3:
                        return '签约经理';
                    case 4:
                        return '普通用户';
                    default:
                        break;
                }
            },
            async offuser() {
                this.showUser = false;
                this.addUser = false;
                this.excelSms = false;
                this.excelUser = false;
            },
            // 搜索
            doSearch() {
                this.userList();
            },

            // 选择日期
            selectedDate(e) {
                if (e) {
                    this.searchObj.start_time = e[0];
                    this.searchObj.end_time = e[1];
                    console.log(this.searchObj);
                } else {
                    this.searchObj.start_time = '';
                    this.searchObj.end_time = '';
                }
            },
            paySelectDate(e) {
                if (e) {
                    this.searchObj.store_start_time = e[0];
                    this.searchObj.store_end_time = e[1];
                    console.log(this.searchObj);
                } else {
                    this.searchObj.store_start_time = '';
                    this.searchObj.store_end_time = '';
                }
            },
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            //页面数量改变
            handleSizeChangeTeam(val) {
                this.teamObj.page = 1;
                this.teamObj.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },


            async getAdminList() {
                this.loading = true;
                let [e, data] = await this.$api.adminListApi(this.searchForm);
                this.loading = false;
                if (e) return;
                if (data.code === 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                }
            },
            //用户列表
            async userList() {
                this.loading = true;
                let [e, data] = await this.$api.userList(this.searchObj);
                console.log('用户列表', data.data.data);
                if (data.code === 200) {
                    this.listInfo = data.data.data;
                    this.total = data.data.total;
                    this.day_count = data.user_count.day_count;
                    this.week_count = data.user_count.week_count;
                    this.month_count = data.user_count.month_count;
                    this.loading = false;
                }
            },
            //公司列表

            // async companyList() {
            //
            //     let [e, data] = await this.$api.companyList();
            //     console.log('用户列表', data.data.data);
            //     if (data.code === 200) {
            //         this.company = data.data;
            //     }
            // },
            async userWalletLog() {
                this.$downFile('/admin/user/wallet/log/excel', this.searchMoneyObj, '钱包记录');

            },
            //用户修改
            async saveUser() {
                this.loading = true;
                let [e, data] = await this.$api.saveUser(this.userInfo);
                console.log(data);
                if (e) return;
                if (data.code === 200) {
                    this.userList();
                    this.$message.success(data.msg);
                    this.loading = false;
                    this.showUser = false;
                    this.UserLoading = false;
                }
            },
            //添加用户
            async adminAddUser() {

                this.loading = true;
                let [e, data] = await this.$api.createUser(this.userAdd);
                console.log(this.userAdd);
                if (e) return;
                if (data.code === 200) {
                    this.userList();
                    this.$message.success(data.msg);
                    this.loading = false;
                    this.UserLoading = false;
                    this.addUser = false;
                }
            },


        }
    };
</script>
<style lang="less">
    .el-table__row:hover .icon-bianji {
        color: rgb(0, 185, 217) !important;
    }

    .el-table__row:hover .icon-shanchu {
        color: rgb(255, 92, 92) !important;
    }
</style>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }
</style>
